.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.description-text {
  font-size: 0.8rem;
}

.navbar-brand {
  margin-right: 0;
}

.text-bg-color, .bg-color {
  background-color: #5a23c8
}

.text-bg-color, .text-color {
  color: #5a23c8 
}

.btn-color {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5a23c8;
  --bs-btn-border-color: #5a23c8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(120, 7, 210);
  --bs-btn-hover-border-color: rgb(100, 6, 150);
  --bs-btn-focus-shadow-rgb: 11,172,204;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(150, 7, 250);
  --bs-btn-active-border-color: rgb(100, 6, 150);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5a23c8;
  --bs-btn-disabled-border-color: #5a23c8;
}

.btn-outline-color {
--bs-btn-color: #5a23c8;
--bs-btn-border-color: #5a23c8;
--bs-btn-hover-color: #fff;
--bs-btn-hover-bg: #5a23c8;
--bs-btn-hover-border-color: #5a23c8;
--bs-btn-focus-shadow-rgb: 13,202,240;
--bs-btn-active-color: #fff;
--bs-btn-active-bg: #5a23c8;
--bs-btn-active-border-color: #5a23c8;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
--bs-btn-disabled-color: #5a23c8;
--bs-btn-disabled-bg: transparent;
--bs-btn-disabled-border-color: #5a23c8;
--bs-gradient: none;
}

.btn-light {
  --bs-btn-color: #5a23c8;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #5a23c8;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211,212,213;
  --bs-btn-active-color: #5a23c8;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5a23c8;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #5a23c8;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248,249,250;
  --bs-btn-active-color: #5a23c8;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.border-color {
  --bs-border-opacity: 1;
  border-color: #5a23c8 !important;
}

:root {
  --fa-style-family: "Font Awesome 6 Pro";
  --fa-style: 400;
  --fa-border-color: #5a23c8;
  --fa-primary-color: #f8f9fa;
  --fa-secondary-color: #5a23c8;
 }


.table-color {
  background-color: rgba(195, 153, 240, 0.5) !important;
}

 .zoom:hover {
  transform: scale(1.05);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  background-color: rgba(255, 255, 255, 0.25);
  transition: 800ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}